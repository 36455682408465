import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import router from './Routes';
  import 'bootstrap/dist/css/bootstrap.min.css';
  import './index.css'
import { ContextProvider } from './Views/Context/ContextProvider';
const root = ReactDOM.createRoot(document.getElementById('root'));

axios.defaults.baseURL="https://gadgetsworld.click/softwareapis/";
//axios.defaults.baseURL="https://mrzgadgets.com/softwareapis/";
//axios.defaults.baseURL="https://azizks.developingdesk.com/softwareapis/";
//axios.defaults.baseURL="http://localhost:8000/";

axios.defaults.headers.post['Content-Type']='application/json';
axios.defaults.headers.post['Accept']='application/json';
axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config){
const token=localStorage.getItem('ACCESS_TOKEN');
config.headers.Authorization = token ? `Bearer ${token}` : '';

return config;

});
axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  const {response} = error;
  if (response.status === 401) {
    localStorage.removeItem('ACCESS_TOKEN')
    // window.location.reload();
  } else if (response.status === 404) {
    //Show not found
  }

  throw error;
})
root.render(
  <React.StrictMode>
   <ContextProvider>
  <RouterProvider router={router} /> 
  </ContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

